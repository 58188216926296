import { isEmptyValue } from '@/helper/data-process';
import { layoutElementIds } from '@/constants/layout';

export const getMainContent = () => document.querySelector(`#${layoutElementIds.mainContent}`);

export function scrollMainContentX(x = null) {
  if (isEmptyValue(x)) {
    return;
  }
  const mainContent = getMainContent();
  if (!mainContent) return;
  mainContent.scrollTo(x, mainContent.scrollTop);
}

export function scrollMainContent({ x = null, y = null } = {}) {
  if (isEmptyValue(x) || isEmptyValue(y)) {
    return;
  }
  const mainContent = getMainContent();
  if (!mainContent) return;
  mainContent.scrollTo(x, y);
}

function getPositionInMainContent(element) {
  const mainContent = getMainContent();
  if (!mainContent) return null;
  const elementPosition = element.getBoundingClientRect();
  if (!elementPosition) return null;
  const mainContentPosition = mainContent.children[0].getBoundingClientRect();
  return {
    top: elementPosition.top - mainContentPosition.top,
    left: elementPosition.left - mainContentPosition.left,
  };
}

function getPositionWithMainContentScrollOffset(positionInMainContent) {
  const offset = { x: 100, y: 160 };
  const getOffet = (value, offset) => (value < offset ? 0 : value - offset);
  return {
    x: getOffet(positionInMainContent.left, offset.x),
    y: getOffet(positionInMainContent.top, offset.y),
  };
}

export function mainContentScrollToElement(element) {
  const position = getPositionInMainContent(element);
  if (!position) return;
  const scrollPosition = getPositionWithMainContentScrollOffset(position);
  scrollMainContent(scrollPosition);
}

export function mainContentScrollToHash(hash) {
  if (!hash) return;
  const element = document.querySelector(hash);
  if (!element) return;
  mainContentScrollToElement(element);
};
