<script setup>
import { debounce } from 'lodash-es/function';
import { computed, inject } from 'vue';
import ButtonPrimary from '@/components/ui/button/ButtonPrimary.vue';

const props = defineProps({
  isSidebarCollapsed: {
    type: Boolean,
    default: false,
  },
});

const sidebarIcon = computed(() => {
  if (props.isSidebarCollapsed) {
    return '/icons/icon-bars.svg';
  }
  return '/icons/icon-bars-shrink.svg';
});

const emit = defineEmits(['toggleSidebar']);

const auths = inject('$auths');

const userName = computed(() => auths.getterUserInfo.value?.name || '');

const emitToggleSidebar = debounce(
  () => {
    emit('toggleSidebar');
  },
  500,
  { leading: true },
);

function logout() {
  auths.logout();
}
</script>

<template>
  <div class="tw-h-[60px]" data-test-id="home-header">
    <div class="tw-h-[60px] tw-fixed tw-w-screen tw-bg-white tw-flex tw-border-b tw-border-solid tw-border-neutral-100">
      <button
        class="tw-flex-none tw-h-full tw-w-[68px] tw-inline-flex tw-justify-center tw-items-center tw-bg-white tw-border-0 tw-border-r tw-border-neutral-100"
        data-test-id="header-sidebar-button"
        @click="emitToggleSidebar"
      >
        <img :data-test-id="isSidebarCollapsed && 'is-collapsed'" :src="sidebarIcon" class="tw-w-5" />
      </button>
      <router-link class="tw-flex-none tw-grow tw-inline-block tw-h-full tw-px-6 tw-pt-4" to="/">
        <img src="/header/Logo-eslite.svg" class="tw-h-7" />
        <img src="/logo-subtract.svg" class="tw-mx-3 tw-mb-[6px] tw-h-[0.625rem]" />
        <img src="/header/Logo-eslite-CMS.svg" class="tw-h-6" alt="誠品電商管理後台" />
      </router-link>
      <div class="tw-flex-none tw-flex tw-items-center tw-px-8">
        <ButtonPrimary type="outlineDisplayOnly" class="tw-flex tw-text-base tw-mr-3" :disabled="true">
          <img src="/icons/icon-user.svg" class="tw-mr-2" />
          <span>{{ userName }}</span>
        </ButtonPrimary>
        <ButtonPrimary type="outlineOrange" class="tw-flex tw-text-base" @click="logout">
          <img src="/icons/icon-exit.svg" class="tw-mr-2" />
          <span>登出</span>
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>
