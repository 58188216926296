import { storeToRefs } from 'pinia';
import { useSidebarStore } from '@/stores/sidebar';

export const useSidebar = () => {
  const sidebarStore = useSidebarStore();
  const { getIsSidebarCollapsed, getIsTemporaryExpandSidebar } = storeToRefs(sidebarStore);
  const { setIsSidebarCollapsed, setIsTemporaryExpandSidebar } = sidebarStore;

  function handleToggleSidebar() {
    if (getIsTemporaryExpandSidebar.value) {
      setIsTemporaryExpandSidebar(false);
      return;
    }
    setIsSidebarCollapsed(!getIsSidebarCollapsed.value);
  }
  function handleTurnOnTemporaryExpandSidebar() {
    setIsTemporaryExpandSidebar(true);
  }
  function handleTurnOffTemporaryExpandSidebar() {
    setIsTemporaryExpandSidebar(false);
  }

  return {
    getIsSidebarCollapsed,
    getIsTemporaryExpandSidebar,
    handleToggleSidebar,
    handleTurnOnTemporaryExpandSidebar,
    handleTurnOffTemporaryExpandSidebar,
  };
};
