import Sdk from 'casdoor-js-sdk';
import { casdoorSigninCallbackPath } from '@/constants/auth';

function getCasdoorClientId() {
  // for snapshot test & yarn dev
  if (import.meta.env.VITE_TYPE === 'development') {
    return '123423423423';
  }
  // yarn start:dev 應該用command： VITE_CASDOOR_CLIENT_ID xxx yarn start:dev
  return import.meta.env.VITE_CASDOOR_CLIENT_ID;
}

const sdkConfig = {
  serverUrl: import.meta.env.VITE_CASDOOR_SERVER_URL,
  clientId: getCasdoorClientId(),
  organizationName: 'eslite',
  appName: 'ec',
  redirectPath: casdoorSigninCallbackPath,
};

export function casdoorPlugin({ app, router, pluginContext }) {
  const CasdoorSDK = new Sdk(sdkConfig);
  const sdkInstance = {
    getSignupUrl: CasdoorSDK.getSignupUrl.bind(CasdoorSDK),
    getSigninUrl: CasdoorSDK.getSigninUrl.bind(CasdoorSDK),
    getUserProfileUrl: CasdoorSDK.getUserProfileUrl.bind(CasdoorSDK),
    getMyProfileUrl: CasdoorSDK.getMyProfileUrl.bind(CasdoorSDK),
    signin: CasdoorSDK.signin.bind(CasdoorSDK),
    isSilentSigninRequested: CasdoorSDK.isSilentSigninRequested.bind(CasdoorSDK),
    silentSignin: CasdoorSDK.silentSignin.bind(CasdoorSDK),
  };
  app.provide('$casdoor-sdk', sdkInstance);

  const callbackPageSignin = async () => {
    const serverUrl = import.meta.env.VITE_API;
    const signinPath = '/api/signin';

    if (import.meta.env.VITE_TYPE !== 'development') {
      console.log('[casdoor] use sdk signin');
      return CasdoorSDK.signin(serverUrl, signinPath);
    }

    // 避開 sdk check state
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');

    const res = await fetch(`${serverUrl}${signinPath}?code=${code}&state=${state}`, {
      method: 'POST',
      credentials: 'include',
    });
    const resJson = await res.json();
    return resJson;
  };
  app.provide('$casdoor-vue', { callbackPageSignin });
}
