/**
 * interface FieldContext {
 *   field: string; // The field's name or label (see next section)
 *   value: any; // the field's current value
 *   form: Record<string, any>; // other values in the form
 *   rule: {
 *     name: string; //the rule name
 *     params?: any[]; // any params sent to it
 *   };
 * }
 */

const zhTWDefaultMessage = ({ field }) => `${field} 格式有誤。`;

const zhTWMessageComposerByRule = {
  required: ({ field }) => `${field} 為必填。`,
  size: ({ field, rule }) => `${field} 的檔案須小於 ${rule.params[0]}KB`,
};

export function zhTWGenerateMessage(fieldContext) {
  const defaultMessage = zhTWDefaultMessage({ field: fieldContext.field || '' });
  const { rule } = fieldContext;
  if (!rule || !rule.name) return defaultMessage;
  const messageComposer = zhTWMessageComposerByRule[rule.name];
  if (!messageComposer) return defaultMessage;
  // console.log(fieldContext);
  return messageComposer(fieldContext);
}
