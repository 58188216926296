<script setup>
import { storeToRefs } from 'pinia';
import { usePageStatusStore } from '@/stores/page-status';
import WarningModal from '@/components/ui/WarningModal.vue';

const pageStatusStore = usePageStatusStore();
const { setPageNormal, clear400Callback } = pageStatusStore;
const { getError400Title, getError400Subtitle } = storeToRefs(pageStatusStore);
function close400Modal() {
  clear400Callback();
  setPageNormal();
}
</script>

<template>
  <WarningModal :title="getError400Title" :subtitle="getError400Subtitle" @close="close400Modal"></WarningModal>
</template>
