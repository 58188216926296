import { shallowReactive } from 'vue';
import { defineStore } from 'pinia';
import { randomNumberFixedLength } from '@/helper/data-process';

export const useToastStore = defineStore('toast', () => {
  const allToasts = shallowReactive({});

  function addToastMessage(text) {
    if (!text) return {};
    const toastKey = randomNumberFixedLength(16);
    const removeToast = () => {
      delete allToasts[toastKey];
    };
    allToasts[toastKey] = {
      key: toastKey,
      text,
      removeToast,
    };
  }

  function clearAllToast() {
    Object.keys(allToasts).forEach((key) => {
      delete allToasts[key];
    });
  }

  return {
    allToasts,
    addToastMessage,
    clearAllToast,
  };
});
