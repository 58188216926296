<script setup>
const props = defineProps({
  text: {
    type: String,
    default: '系統錯誤',
  },
});

const emit = defineEmits(['close']);
const emitClose = () => emit('close');
</script>

<template>
  <div class="tw-rounded tw-pl-4 tw-w-[27.5rem] tw-border tw-border-solid tw-border-brand-red-200 tw-bg-cube-red-50 tw-flex tw-items-center" data-test-id="warning-toast">
    <img src="/icons/icon-warning-triangle.svg" class="tw-mr-2" />
    <span>{{ text }}</span>
    <div class="tw-ml-auto tw-px-4 tw-py-4 tw-cursor-pointer">
      <img src="/icons/icon-close-red.svg" class="" @click="emitClose" />
    </div>
  </div>
</template>
