import { createApp } from 'vue';
import { createPinia } from 'pinia';
import initRouter from '@/router';
import { addPlugins } from '@/plugins/main';
import '@/assets/styles/tailwind.css';
import App from '@/App.vue';

const pluginContext = {};
const pinia = createPinia();
const app = createApp(App);
app.use(pinia);

const { router } = initRouter();
app.use(router);

addPlugins({ app, router, pluginContext });

app.mount('#app');

if (import.meta.env.VITE_TYPE === 'development') {
  window.globalApp = app;
}
