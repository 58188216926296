<template>
  <component :is="layoutComponent"></component>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import DefaultLayout from '@/layouts/default.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import { layoutEnum } from '@/constants/layout';

const layoutByKey = {
  [layoutEnum.default]: DefaultLayout,
  [layoutEnum.empty]: EmptyLayout,
};
const route = useRoute();
const layoutComponent = computed(() => {
  if (!route.name) {
    return layoutByKey.empty;
  }
  return layoutByKey[route.meta.layout] || layoutByKey.default;
});
</script>

<script>
export default {
  name: 'App',
};
</script>
