import axiosPlugin from '@/plugins/axios';
import { casdoorPlugin } from '@/plugins/auth/casdoor';
import { authsPlugin } from '@/plugins/auth/auths';
import veeValidatePlugin from '@/plugins/vee-validate';
import { routerPlugin } from '@/plugins/router/main';
import clickOutside from '@/plugins/directives/click-outside';

export function addPlugins({ app, router, pluginContext }) {
  const plugins = [axiosPlugin, casdoorPlugin, authsPlugin, routerPlugin, veeValidatePlugin, clickOutside];
  plugins.forEach((plugin) => plugin({ app, router, pluginContext }));
}
