import axios from 'axios';
import { getToken } from '@/helper/storage/auth';

const globalAxios = () => {
  axios.interceptors.request.use((reqConfig) => {
    if (reqConfig === undefined) return reqConfig;
    // 自動帶入 token
    if (reqConfig.url.startsWith(import.meta.env.VITE_API)) {
      const token = getToken();
      if (!reqConfig.headers) {
        reqConfig.headers = {};
      }
      reqConfig.headers.Authorization = token;
    }
    return reqConfig;
  });
  axios.interceptors.response.use(async (response) => {
    return response;
  });
};

export default globalAxios;
