import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('sidebar', () => {
  const isSidebarCollapsed = ref(false);
  const isTemporaryExpandSidebar = ref(false);

  const getIsSidebarCollapsed = computed(() => isSidebarCollapsed.value);
  const getIsTemporaryExpandSidebar = computed(() => isTemporaryExpandSidebar.value);

  function setIsSidebarCollapsed(value) {
    isSidebarCollapsed.value = value;
  }
  function setIsTemporaryExpandSidebar(value) {
    isTemporaryExpandSidebar.value = value;
  }

  return {
    getIsSidebarCollapsed,
    getIsTemporaryExpandSidebar,
    setIsSidebarCollapsed,
    setIsTemporaryExpandSidebar,
  };
});
