import { defineVeeValidateGlobalRule } from '@/validator/global-rule';
import { initVeeValidateI18n } from '@/validator/language';
import { definedYupGlobalMethod } from '@/validator/yup-global-rules';

export default () => {
  // vee-validate
  defineVeeValidateGlobalRule();
  initVeeValidateI18n(`zh_TW`);
  // yup
  definedYupGlobalMethod();
};
