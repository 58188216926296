import axios from 'axios';

const API_PATH = `${import.meta.env.VITE_API}${import.meta.env.VITE_API_USER_INFO}`;

export default () => {
  const params = {};
  if (import.meta.env.VITE_TYPE === 'development') {
    params.code = 200;
  }
  return axios({
    method: 'get',
    url: API_PATH,
    params,
  });
};
