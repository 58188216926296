<script setup>
import ButtonPrimary from '@/components/ui/button/ButtonPrimary.vue';
import ModalBase from '@/components/ui/modal/ModalBase.vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);
function emitClose() {
  emit('close');
}
</script>

<template>
  <ModalBase>
    <template #body>
      <div v-if="title" class="tw-text-center tw-text-2xl tw-font-semibold tw-text-neutral-900">{{ title }}</div>
      <slot v-else></slot>
    </template>
    <template #footer>
      <ButtonPrimary class="tw-m-auto ec-modal-button" size="large" type="outline" @click="emitClose">確定</ButtonPrimary>
    </template>
  </ModalBase>
</template>
