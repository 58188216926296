import { homeAdStep1Link } from '@/constants/links/home-ad-links';

export const sidebarCategory = {
  g_ad: {
    key: 'g_ad',
    icon: '/icons/sidebar/sidebar-ad.svg',
    name: '廣告管理',
    childrenPathRegex: /^\/home-ad/,
    children: {
      g_ad__home: {
        key: 'g_ad__home',
        link: homeAdStep1Link.desktop,
        name: '首頁',
        pathRegex: /^\/home-ad/,
      },
    },
  },
};
