import { ref } from 'vue';
import { createRouteMiddleware } from '@/plugins/router/middleware';
import { changePageTitle } from '@/helper/meta/page-title';

export function routerPlugin({ app, router, pluginContext }) {
  const isFirstLoad = ref(true);
  const routeMiddleware = createRouteMiddleware({ app, pluginContext, isFirstLoad });
  router.beforeEach((to, from, next) => {
    // console.log('router.beforeEach');
    routeMiddleware(to, from, next);
  });
  router.afterEach((to, from) => {
    if (isFirstLoad.value) {
      isFirstLoad.value = false;
    }
    changePageTitle();
  });
}
