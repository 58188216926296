import { casdoorSigninCallbackPath } from '@/constants/auth';

export default async function authMiddleware({ pluginContext, to, redirect }) {
  await pluginContext.auths.watchInitAuthFinished(() => {
    console.log('auth middleware done');
    if (to.path === casdoorSigninCallbackPath) {
      return;
    }
    if (!pluginContext.auths.getterIsSignin.value && to.path !== '/login') {
      redirect('/login');
      return;
    }
  });
}
