import { computed, ref, watch } from 'vue';
import { clearToken, setToken, getToken, checkToken } from '@/helper/storage/auth';
import { fetchAndFormatUserInfo } from '@/helper/api-formatted/auth';
import { isEmptyValue } from '@/helper/data-process';

function createAuthService({ router }) {
  const isInitAuthFinished = ref(false);
  const userInfo = ref(null);

  const getterIsSignin = computed(() => !isEmptyValue(userInfo.value?.name));
  const getterUserInfo = computed(() => userInfo.value);

  function setIsInitAuthFinished(value) {
    isInitAuthFinished.value = value;
    console.log('update isInitAuthFinished', value);
  }

  function watchInitAuthFinished(callback) {
    if (isInitAuthFinished.value) {
      callback();
      return;
    }
    return new Promise((resolve) => {
      watch(isInitAuthFinished, () => {
        callback();
        resolve(true);
      });
    });
  }

  function logout(skipRedirect = false) {
    clearToken();
    userInfo.value = null;
    if (skipRedirect) {
      return;
    }
    if (router.currentRoute.value.path !== '/login') {
      router.push('/login');
    }
  }

  function verifyTokenAndInitUser({ token, skipRedirect = false }) {
    if (!checkToken(token)) {
      console.log('[verifyTokenAndInitUser] invalid token', token);
      logout(skipRedirect);
      return Promise.reject();
    }
    setToken(token);
    return fetchAndFormatUserInfo()
      .then((userData) => {
        console.log('[verifyTokenAndInitUser] userData', userData);
        userInfo.value = userData;
      })
      .catch((e) => {
        console.log('[verifyTokenAndInitUser] init user fail', e);
        logout(skipRedirect);
      });
  }

  function initVerifyToken() {
    const token = getToken();
    // 初始尚未進入router，交由 router middleware redirect
    return verifyTokenAndInitUser({ token, skipRedirect: true })
      .catch((e) => {
        console.log('[initVerifyToken] fail');
      })
      .finally(() => {
        setIsInitAuthFinished(true);
      });
  }

  return {
    logout,
    getToken,
    getterIsSignin,
    getterUserInfo,
    initVerifyToken,
    watchInitAuthFinished,
    verifyTokenAndInitUser,
  };
}

export function authsPlugin({ app, router, pluginContext }) {
  const authService = createAuthService({ router });
  app.provide('$auths', authService);
  pluginContext.auths = authService;
  // app 初始 verify token
  authService.initVerifyToken();
}
