<script setup>
import { useToastStore } from '@/stores/toast-store';
import WarningToast from '@/components/ui/WarningToast.vue';

const { allToasts } = useToastStore();
</script>

<template>
  <div class="ec-z-toast ec-absolute-x-center tw-top-[3rem]">
    <template v-for="toastItem in allToasts" :key="toastItem.key">
      <WarningToast :text="toastItem.text" class="tw-mt-2" @close="toastItem.removeToast" />
    </template>
  </div>
</template>
