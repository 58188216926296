import { configure } from 'vee-validate';
import { zhTWGenerateMessage } from '@/validator/message-composer/tw';
import * as yup from 'yup';
import yupZhTW from '@/validator/yup-language/tw';

const yupLangs = {
  zh_TW: yupZhTW,
};

export const initVeeValidateI18n = (lang = 'zh_TW') => {
  configure({
    generateMessage: zhTWGenerateMessage,
  });

  yup.setLocale(yupLangs[lang]);
};
