import { forEachObjIndexed, last, uniq } from 'ramda';
import { isFunction } from '@/helper/data-process';
import { MiddlewareRedirectException } from '@/exception/middleware-exception';

/**
 * global middleware
 */
const globalMiddlewares = ['auth', 'redirect', 'reset-global-variable'];

const getMiddlewareImportGlob = () => {
  // middleware 檔案位置
  const modules = import.meta.glob('../../middleware/*.js', { eager: true });
  const modulesByName = {};
  forEachObjIndexed((value, key) => {
    const name = last(key.split('/')).replace('.js', '');
    modulesByName[name] = value;
  }, modules);
  return modulesByName;
};

const middlewareImportGlob = getMiddlewareImportGlob();

const getLocalMiddlewares = (middleware) => {
  if (!middleware) return [];
  if (Array.isArray(middleware)) return middleware;
  return [middleware];
};

const requireMiddlewareFunction = (middleware) => {
  if (isFunction(middleware)) return middleware;
  return middlewareImportGlob[middleware]?.default;
};

export const createRouteMiddleware = function ({ isFirstLoad, pluginContext }) {
  return (to, from, next) => {
    // global middleware + vue檔案 SFC middleware
    const localMiddlewares = getLocalMiddlewares(to.meta.middleware);
    const middlewares = uniq([...globalMiddlewares, ...localMiddlewares]);

    middlewares
      .reduce(
        (promise, middleware) =>
          promise.then(() => {
            const middlewareFunction = requireMiddlewareFunction(middleware);
            // 執行 middleware
            return middlewareFunction({
              pluginContext,
              to,
              from: isFirstLoad.value ? to : from,
              route: to,
              redirect: (arg) => {
                if (isFirstLoad.value) {
                  isFirstLoad.value = false;
                }
                next(arg);
                throw new MiddlewareRedirectException();
              },
            });
          }),
        Promise.resolve(),
      )
      .then(next)
      .catch((error) => {
        if (error instanceof MiddlewareRedirectException) return;
        console.log(error);
        next();
      });
  };
};
