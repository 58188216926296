const __pages_import_0__ = () => import("/src/pages/home-ad/step2-slot-group-list/b026.vue");
const __pages_import_1__ = () => import("/src/pages/home-ad/step2-slot-group-list/b006.vue");
const __pages_import_2__ = () => import("/src/pages/home-ad/step1/mobile.vue");
const __pages_import_3__ = () => import("/src/pages/home-ad/step1/desktop.vue");
const __pages_import_4__ = () => import("/src/pages/login.vue");
import __pages_import_5__ from "/src/pages/index.vue";
const __pages_import_6__ = () => import("/src/pages/callback.vue");
const __pages_import_7__ = () => import("/src/pages/home-ad/edit-slot/[adKey].vue");
const __pages_import_8__ = () => import("/src/pages/home-ad/step2-slot-list/[adKey].vue");

const routes = [{"name":"home-ad-step2-slot-group-list-b026","path":"/home-ad/step2-slot-group-list/b026","component":__pages_import_0__,"props":true,"meta":{"layout":"default"}},{"name":"home-ad-step2-slot-group-list-b006","path":"/home-ad/step2-slot-group-list/b006","component":__pages_import_1__,"props":true,"meta":{"layout":"default"}},{"name":"home-ad-step1-mobile","path":"/home-ad/step1/mobile","component":__pages_import_2__,"props":true,"meta":{"layout":"default"}},{"name":"home-ad-step1-desktop","path":"/home-ad/step1/desktop","component":__pages_import_3__,"props":true,"meta":{"layout":"default"}},{"name":"login","path":"/login","component":__pages_import_4__,"props":true,"meta":{"layout":"empty"}},{"name":"index","path":"/","component":__pages_import_5__,"props":true,"meta":{"layout":"default"}},{"name":"callback","path":"/callback","component":__pages_import_6__,"props":true,"meta":{"layout":"empty"}},{"name":"home-ad-edit-slot-adKey","path":"/home-ad/edit-slot/:adKey","component":__pages_import_7__,"props":true,"meta":{"layout":"default"}},{"name":"home-ad-step2-slot-list-adKey","path":"/home-ad/step2-slot-list/:adKey","component":__pages_import_8__,"props":true,"meta":{"layout":"default"}}];

export default routes;