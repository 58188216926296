import { authKey } from '@/constants/storage/auth-key';

function removeBearer(token) {
  return token.replace(/^Bearer\s/, '');
}

export function getToken() {
  return `Bearer ${localStorage.getItem(authKey.token)}`;
}

export function checkToken(token) {
  const matched = token.match(/^(Bearer )?[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+$/);
  return !!matched;
}

export function setToken(token) {
  localStorage.setItem(authKey.token, removeBearer(token));
}

export function clearToken() {
  setToken('');
}
