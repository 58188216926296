<template>
  <div class="ec-modal-backdrop ec-flex-all-center">
    <div class="ec-modal-box tw-rounded-lg tw-bg-white">
      <div class="tw-w-[30rem] tw-p-8">
        <slot name="body"></slot>
      </div>
      <div class="tw-p-8 tw-pt-0">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
