import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Taipei');
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const dateTimeFormatter = 'YYYY/MM/DD HH:mm:ss';
const dateFormatter = 'YYYY/MM/DD';
const timeFormatter = 'HH:mm:ss';

/**
 * 取得當前 Taipei 日期
 * @return {string}
 */
export const getCurrentTaipeiDateTime = (formatter) => dayjs.tz().format(formatter || dateTimeFormatter);

/**
 * datetime format (YMD hms)
 * @param {datetime|string} d datetime
 * @returns {string} YYYY/MM/DD HH:mm:ss
 */
export const dateTimeFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(dateTimeFormatter));

/**
 * datetime format (YMD hms)
 * @param {datetime|string} d datetime
 * @param {string} f format string
 * @returns {string} datetime string
 */
export const dateTimeFormatWithCustom = (d, f = dateTimeFormatter) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(f));

/**
 * datetime format (YMD)
 * @param {datetime|string} d datetime
 * @returns {string} YYYY/MM/DD
 */
export const dateFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(dateFormatter));

/**
 * datetime format (hms)
 * @param {datetime|string} d datetime
 * @returns {string} HH:mm:ss
 */
export const timeFormat = (d) => (typeof d !== 'undefined' && !dayjs(d).isValid() ? '' : dayjs(d).format(timeFormatter));

/**
 * filter year
 * @const {string} datetime string
 * @return {string} year
 * */
export const year = (d) => dayjs(d).format('YYYY');

/**
 * filter month
 * @const {string} datetime string
 * @return {string} month
 * */
export const month = (d) => dayjs(d).format('MM');

/**
 * filter date
 * @const {string} datetime string
 * @return {string} date
 * */
export const day = (d) => dayjs(d).format('DD');

/**
 * filter hour
 * @const {string} datetime string
 * @return {string} hour
 * */
export const hour = (d) => dayjs(d).format('HH');

export default dayjs;
