import { defineRule } from 'vee-validate';
import { required, email, ext, min, image, dimensions, size } from '@vee-validate/rules';

export function defineVeeValidateGlobalRule() {
  defineRule('required', required);
  defineRule('email', email);
  defineRule('ext', ext);
  defineRule('min', min);
  defineRule('image', image);
  defineRule('dimensions', dimensions);
  defineRule('size', size);
}
