<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { sidebarCategory } from '@/constants/sidebar-category';
import { findCurrentSidebarCategory } from '@/helper/sidebar/sidebar-category';

const props = defineProps({
  isSidebarCollapsed: {
    type: Boolean,
    default: false,
  },
  isTemporaryExpandSidebar: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();

const currentPageL1Key = ref('');
const l1OpenStatusByKey = ref({});
const activeL2Key = ref('');

const emit = defineEmits(['turnOnTemporaryExpandSidebar', 'turnOffTemporaryExpandSidebar']);

watch(
  () => route.path,
  (newPath) => {
    const { l1Key, l2Key } = findCurrentSidebarCategory(newPath);
    currentPageL1Key.value = l1Key;
    activeL2Key.value = l2Key;
  },
  {
    immediate: true,
  },
);

function toggleL1Status(l1Key) {
  l1OpenStatusByKey.value[l1Key] = !l1OpenStatusByKey.value[l1Key];
}
function turnOnTemporaryExpandSidebar(l1Key) {
  emit('turnOnTemporaryExpandSidebar');
  l1OpenStatusByKey.value[l1Key] = true;
}
function handlel2Click(l1Key, l2Key) {
  currentPageL1Key.value = l1Key;
  emit('turnOffTemporaryExpandSidebar');
  activeL2Key.value = l2Key;
}
function getChevronIcon(l1Key) {
  return l1OpenStatusByKey.value[l1Key] ? '/icons/icon-chevron-up.svg' : '/icons/icon-chevron-right.svg';
}
function checkActiveL2(l2Key) {
  return l2Key === activeL2Key.value;
}
</script>

<template>
  <div v-if="isSidebarCollapsed && !isTemporaryExpandSidebar" class="tw-w-[67px] tw-p-2">
    <template v-for="l1 in sidebarCategory" :key="l1.key">
      <div
        class="tw-w-full tw-h-11 ec-flex-all-center tw-cursor-pointer tw-rounded hover:tw-bg-brand-olive-100"
        :class="currentPageL1Key === l1.key && 'tw-bg-brand-olive-100'"
        @click="turnOnTemporaryExpandSidebar(l1.key)"
      >
        <img :src="l1.icon" class="logo" :alt="l1.name" />
      </div>
    </template>
  </div>
  <div v-else class="tw-w-60 tw-p-2">
    <template v-for="l1 in sidebarCategory" :key="l1.key">
      <div
        class="tw-px-4 tw-h-12 tw-flex tw-items-center tw-rounded hover:tw-bg-brand-olive-100 tw-cursor-pointer"
        :class="currentPageL1Key === l1.key && 'tw-bg-brand-olive-100'"
        @click="toggleL1Status(l1.key)"
      >
        <img :src="l1.icon" class="tw-flex-none" :alt="l1.name" />
        <div class="tw-inline-block tw-flex-1 tw-mx-4 tw-font-semibold tw-text-sm tw-text-brand-olive-700">{{ l1.name }}</div>
        <img :src="getChevronIcon(l1.key)" class="tw-flex-none" />
      </div>
      <div v-if="l1OpenStatusByKey[l1.key]" class="tw-py-2">
        <template v-for="l2 in l1.children" :key="l2.name">
          <router-link
            :to="`${l2.link}`"
            :class="checkActiveL2(l2.key) && 'tw-bg-neutral-50'"
            class="tw-flex tw-h-[38px] tw-items-center tw-no-underline tw-rounded hover:tw-bg-neutral-50"
            @click="handlel2Click(l1.key, l2.key)"
          >
            <div class="tw-w-[52px]">
              <div class="tw-h-[38px] tw-relative tw-mx-auto tw-w-px tw-bg-neutral-100">
                <div v-if="checkActiveL2(l2.key)" class="tw-absolute tw-left-0 tw-inset-y-0 tw-my-[7px] tw-w-[3px] tw-rounded tw-bg-brand-olive-700"></div>
              </div>
            </div>
            <div class="tw-flex-1 tw-text-sm tw-text-brand-olive-700">{{ l2.name }}</div>
          </router-link>
        </template>
      </div>
    </template>
  </div>
</template>
