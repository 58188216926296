import { ext } from '@vee-validate/rules';
import { isEmptyValue, checkOneOfTargetFieldsExist } from '@/helper/data-process';
import { transformAllTextToNoSpace, transformTextLink } from '@/helper/form/transform';

export const websiteString = /https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
// chinese: [\u4e00-\u9fa5\u3400-\u4DBF。，、；：「」『』（）？！…﹏《》〈〉＿．—～]
export const zhEnNumberRegex = /^[\u0020-\u007e\u4e00-\u9fa5\u3400-\u4DBF]*$/u;
// export const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g; // 中文標點符號也會 match
export const productSnRegex = /^[0-9]{13}$/;
export const productSnGuidOrIsbnRegex = /^[0-9a-zA-Z]{10,25}$/;
export const productMultiSnGuidOrIsbnRegex = /^[0-9a-zA-Z]{10,25}(\n[0-9a-zA-Z]{10,25})*$/;
export const colorRegex = /^#[0-9a-zA-Z]{6}$/;

export const checkSizeKb = (sizeKb) => (value) => {
  if (!value) return true; // 非必選
  return value.size / 1024 < sizeKb;
};

export const checkImageDimension =
  ({ width = null, height = null } = {}) =>
  (value) => {
    return new Promise((resolve) => {
      if (!value) {
        return resolve(true); // 非必選
      }
      if (width === null) {
        return resolve(true);
      }
      const img = new Image();
      img.onload = function () {
        resolve(img.width === width && img.height === height);
      };
      img.onerror = function () {
        resolve(false);
      };
      img.src = URL.createObjectURL(value);
    });
  };

export const checkFileType = (fileTypeList) => (value) => {
  if (!value) return true; // 非必選
  return ext(value, fileTypeList);
};

export const checkUrl = (value) => {
  if (!value) return true;
  return value.trim().match(websiteString);
};

function filterChinesePunctuation(str) {
  return str.replace(/[。，、；：「」『』（）？！…﹏《》〈〉＿．—～]/g, '');
}
export const checkZhEnNumber = (value) => {
  if (!value) return true;
  return zhEnNumberRegex.test(filterChinesePunctuation(value));
};

export const checkAtListOneTextLink =
  ({ requiredFields = ['text', 'link'] }) =>
  (value) => {
    if (!value || !value.length) return true;
    const transformedList = value.map(transformTextLink);
    const firstItem = transformedList.find((item) => {
      return checkOneOfTargetFieldsExist({ inputObj: item, fields: requiredFields });
    });
    return !isEmptyValue(firstItem);
  };

export const checkObjectArrayMinLength =
  ({ minLength, requiredItemFields = ['text', 'link'] }) =>
  (value) => {
    if (!value) return true;
    const transformedList = value.map(transformAllTextToNoSpace);
    const validItemList = transformedList.filter((item) => {
      return checkOneOfTargetFieldsExist({ inputObj: item, fields: requiredItemFields });
    });
    return validItemList.length >= minLength;
  };

export const checkColor = (value) => {
  if (!value) return true;
  return colorRegex.test(value);
};

export const checkProductSnGuidOrIsbn = (value) => {
  if (!value) return true;
  return productSnGuidOrIsbnRegex.test(value);
};

export const checkMultiProductSnGuidOrIsbn = (value) => {
  if (!value) return true;
  return productMultiSnGuidOrIsbnRegex.test(value);
};
