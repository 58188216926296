import { sidebarCategory } from '@/constants/sidebar-category';

export function findCurrentSidebarCategory(routePath) {
  const l1Key = Object.keys(sidebarCategory).find((key) => {
    return sidebarCategory[key].childrenPathRegex.test(routePath);
  });
  if (!l1Key) {
    return { l1Key: '', l2Key: '' };
  }
  const l2Key = Object.keys(sidebarCategory[l1Key].children).find((key) => {
    return sidebarCategory[l1Key].children[key].pathRegex.test(routePath);
  });

  return { l1Key, l2Key: l2Key || '' };
}
