import { isEmptyValue, emptyReplace, isString } from '@/helper/data-process';
import { mapObjIndexed } from 'ramda';

function transformStringFields({ inputObj, trimFields = [], noSpaceFields = [] }) {
  if (isEmptyValue(inputObj)) return inputObj;
  return mapObjIndexed((value, key) => {
    if (!isString(value)) return value;
    if (trimFields.includes(key)) return emptyReplace(value, '').trim();
    if (noSpaceFields.includes(key)) return emptyReplace(value, '').replace(/\s/g, '');
    return value;
  }, inputObj);
}

export function transformTextLink(formItem) {
  return transformStringFields({ inputObj: formItem, trimFields: ['link'], noSpaceFields: ['text'] });
}

export function transformAllTextToNoSpace(inputObj) {
  if (isEmptyValue(inputObj)) return inputObj;
  return mapObjIndexed((value, key) => {
    if (!isString(value)) return value;
    return value.replace(/\s/g, '');
  }, inputObj);
}
