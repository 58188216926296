const defaultTitle = '電商管理後台 - 誠品';

export function composePageTitle(pageName) {
  if (!pageName) {
    return defaultTitle;
  }
  return `${pageName} - ${defaultTitle}`;
}

export function changePageTitle(newTitle) {
  const finalTitle = newTitle || defaultTitle;
  document.title = finalTitle;
  const titleElement = document.head?.querySelector('title');
  if (titleElement) {
    titleElement.innerHTML = finalTitle;
  }
}

export function changePageTitleByName(newPageName) {
  const newTitle = composePageTitle(newPageName);
  changePageTitle(newTitle);
}
