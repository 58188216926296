import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';
import { scrollMainContent } from '@/helper/scroll/layout';
import { layoutElementIds, layoutEnum } from '@/constants/layout';

function scrollBehavior(to, from, savedPosition) {
  if (!to.hash) {
    setTimeout(() => {
      scrollMainContent({ x: 0, y: 0 });
    }, 0);
  }

  if (to.meta.layout === layoutEnum.empty) {
    return { left: 0, right: 0 };
  }
  // [ToDo] 此處測試無效，因此使用 scrollMainContent
  return {
    el: `#${layoutElementIds.mainContent}`,
    left: 0,
    top: 0,
  };
}

export default function initRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior,
  });
  return { router };
}
