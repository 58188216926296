<script setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'filled',
    validator: function (value) {
      return ['filled', 'outline', 'outlineDisplayOnly', 'outlineOrange'].indexOf(value) !== -1;
    },
  },
  size: {
    type: String,
    default: 'medium',
    validator: function (value) {
      return ['small', 'medium', 'large'].indexOf(value) !== -1;
    },
  },
  isSubmitButton: {
    type: Boolean,
    default: false,
  },
});

const baseClasses = 'ec-btn';
const classesByType = {
  filled: 'ec-btn-brand-olive',
  outline: 'ec-btn-outline-brand-olive',
  outlineDisplayOnly: 'ec-btn-outline-brand-olive__no_effect',
  outlineOrange: 'ec-btn-outline-cube-orange',
};
const classesBySize = {
  small: 'ec-btn-sm',
  medium: 'ec-btn-base',
  large: 'ec-btn-lg',
};

const classes = computed(() => {
  return `${baseClasses} ${classesByType[props.type]} ${classesBySize[props.size]}`;
});
</script>

<script>
export default {
  name: 'PrimaryButton',
};
</script>

<template>
  <button :type="isSubmitButton ? 'submit' : 'button'" :class="classes">
    <slot></slot>
  </button>
</template>
